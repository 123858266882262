import { RouteComponentProps } from "@reach/router"
import React, { ReactNode } from "react"
import { useIntl } from "react-intl"
import { FormattedMessage } from "../components/general/FormattedMessage/FormattedMessage"
import { Navbar } from "../components/general/Navbar/Navbar"
import { NewsModal } from "../components/general/News/components/NewsModal/NewsModal"
import { Notifications } from "../components/general/Notifications/Notifications"
import { Seo } from "../components/general/Seo/Seo"
import { StaticInitialization } from "../components/general/StaticInitialization/StaticInitialization"
import { Footer } from "../components/marketing/Footer"
import { SitemapSearchInput } from "../components/sitemaps/SitemapSearchInput/SitemapSearchInput"
import { SitemapSearchResults } from "../components/sitemaps/SitemapSearchResults/SitemapSearchResults"
import { Container } from "../components/UI/Container"
import { Logo } from "../components/UI/Logo"

const Hero: React.FC<{
  label?: ReactNode
  title: ReactNode
  description: ReactNode
}> = (props) => {
  return (
    <div className="relative pt-10">
      <div
        style={{
          animationDelay: "300ms",
        }}
        className="absolute right-20 top-20 hidden h-20 w-20 animate-bounce  rounded-full bg-pink-50 lg:block"
      ></div>
      <div className="absolute left-20 top-28 hidden h-20 w-20 animate-bounce  rounded-xl bg-blue-50 delay-300 lg:block"></div>

      <div className="flex items-center justify-center">
        <Logo className="h-10 w-10" />
      </div>

      <Container className="mt-8 flex flex-col items-center justify-center pb-4 text-center">
        {props.label && (
          <p className="text-center  text-lg font-medium text-pink-400">
            {props.label}
          </p>
        )}
        <h1 className="mx-auto flex max-w-3xl flex-col  text-4xl font-semibold leading-tight tracking-tight text-slate-900 ">
          {props.title}
        </h1>

        {props.description && (
          <div
            className={
              "mx-auto mt-4 max-w-4xl text-lg leading-normal tracking-tight text-slate-500"
            }
          >
            {props.description}
          </div>
        )}
      </Container>
    </div>
  )
}

export const SitemapsRoute: React.FC<RouteComponentProps> = () => {
  const intl = useIntl()

  return (
    <StaticInitialization>
      <Seo
        title={intl.formatMessage({
          id: `sitemapy/seo/title`,
        })}
        description={intl.formatMessage({
          id: `sitemapy/seo/description`,
        })}
        lang={intl.locale}
        langUrls={[
          {
            lang: "en",
            url: "/sitemapy/",
            isDefault: true,
          },
          {
            lang: "fr",
            url: "/fr/sitemapy/",
            isDefault: false,
          },
          {
            lang: "pt",
            url: "/pt/sitemapy/",
            isDefault: false,
          },
          {
            lang: "es",
            url: "/es/sitemapy/",
            isDefault: false,
          },
          {
            lang: "ru",
            url: "/ru/sitemapy/",
            isDefault: false,
          },
          {
            lang: "ko",
            url: "/ko/sitemapy/",
            isDefault: false,
          },
          {
            lang: "de",
            url: "/de/sitemapy/",
            isDefault: false,
          },
          {
            lang: "tr",
            url: "/tr/sitemapy/",
            isDefault: false,
          },
        ]}
      />

      <div className="relative">
        <Navbar />

        <Hero
          label={<FormattedMessage id="sitemapy/hero/label" />}
          title={<FormattedMessage id="sitemapy/hero/title" />}
          description={<FormattedMessage id="sitemapy/hero/description" />}
        />

        <SitemapSearchInput />
        <SitemapSearchResults />
      </div>

      <NewsModal />
      <Notifications />
      <Footer />
    </StaticInitialization>
  )
}
